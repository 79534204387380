import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { DepartmentComponent } from './components/department/department.component';
import { AdddepartmentComponent } from './components/department/adddepartment/adddepartment.component';
import { AddcategoryComponent } from './components/category/addcategory/addcategory.component';
import { CategoryComponent } from './components/category/category.component';
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { AddsubcategoryComponent } from './components/subcategory/addsubcategory/addsubcategory.component';
import { BrandComponent } from './components/brand/brand.component';
import { AddbrandComponent } from './components/brand/addbrand/addbrand.component';
import { UomComponent } from './components/uom/uom.component';
import { AdduomComponent } from './components/uom/adduom/adduom.component';
import { StatusComponent } from './components/status/status.component';
import { AddstatusComponent } from './components/status/addstatus/addstatus.component';
import { AddwarehouseComponent } from './components/warehouse/addwarehouse/addwarehouse.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { CityComponent } from './components/city/city.component';
import { AddcityComponent } from './components/city/addcity/addcity.component';
import { BannertypeComponent } from './components/bannertype/bannertype.component';
import { AddbannertypeComponent } from './components/bannertype/addbannertype/addbannertype.component';
import { BannerComponent } from './components/banner/banner.component';
import { AddbannerComponent } from './components/banner/addbanner/addbanner.component';
import { AreaComponent } from './components/area/area.component';
import { AddareaComponent } from './components/area/addarea/addarea.component';
import { VendorComponent } from './components/vendor/vendor.component';
import { AddvendorComponent } from './components/vendor/addvendor/addvendor.component';
import { ProductComponent } from './components/product/product.component';
import { AddproductComponent } from './components/product/addproduct/addproduct.component';
import { TaxgroupComponent } from './components/taxgroup/taxgroup.component';
import { AddtaxgroupComponent } from './components/taxgroup/addtaxgroup/addtaxgroup.component';
import { FoodsymbolComponent } from './components/foodsymbol/foodsymbol.component';
import { AddfoodsymbolComponent } from './components/foodsymbol/addfoodsymbol/addfoodsymbol.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { AddcertificateComponent } from './components/certificate/addcertificate/addcertificate.component';
import { ProductsectionComponent } from './components/productsection/productsection.component';
import { AddproductsectionComponent } from './components/productsection/addproductsection/addproductsection.component';
import { SpecificationComponent } from './components/specification/specification.component';
import { AddspecificationComponent } from './components/specification/addspecification/addspecification.component';
import { DeliverytypeComponent } from './components/deliverytype/deliverytype.component';
import { AdddeliverytypeComponent } from './components/deliverytype/adddeliverytype/adddeliverytype.component';
import { DeliveryboyComponent } from './components/deliveryboy/deliveryboy.component';
import { AdddeliveryboyComponent } from './components/deliveryboy/adddeliveryboy/adddeliveryboy.component';
import { DeliveryslotComponent } from './components/deliveryslot/deliveryslot.component';
import { AdddeliveryslotComponent } from './components/deliveryslot/adddeliveryslot/adddeliveryslot.component';
import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { LoginComponent } from './components/login/login.component';
import { ContentmanagementComponent } from './components/contentmanagement/contentmanagement.component';
import { AddcontentmanagementComponent } from './components/contentmanagement/addcontentmanagement/addcontentmanagement.component';
import { OrdersComponent } from './components/orders/orders.component';
import { AddordersComponent } from './components/orders/addorders/addorders.component';
import { PageComponent } from './components/page/page.component';
import { AddpageComponent } from './components/page/addpage/addpage.component';
import { DeliveryorderComponent } from './components/deliveryorder/deliveryorder.component';
import { AdddeliveryorderComponent } from './components/deliveryorder/adddeliveryorder/adddeliveryorder.component';
import { AddshipmentComponent } from './components/deliveryorder/addshipment/addshipment.component';
import { DeliverychargesComponent } from './components/deliverycharges/deliverycharges.component';
import { AdddeliverychargesComponent } from './components/deliverycharges/adddeliverycharges/adddeliverycharges.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { AddcouponComponent } from './components/coupon/addcoupon/addcoupon.component';
import { PaymodeComponent } from './components/paymode/paymode.component';
import { AddpaymodeComponent } from './components/paymode/addpaymode/addpaymode.component';
import { OfferComponent } from './components/offer/offer.component';
import { AddofferComponent } from './components/offer/addoffer/addoffer.component';
import { AddorderaddressComponent } from './components/orders/addorderaddress/addorderaddress.component';
import { WalletsComponent } from './components/wallets/wallets.component';
import { LoyaltyComponent } from './components/loyalty/loyalty.component';
import { TestComponent } from './components/test/test.component';
import { CustomerComponent } from './components/customer/customer.component';
import { AddcustomerComponent } from './components/customer/addcustomer/addcustomer.component';
import { ViewcustomerComponent } from './components/customer/viewcustomer/viewcustomer.component';
import { AddaddressComponent } from './components/customer/addaddress/addaddress.component';
import { AddloyaltyComponent } from './components/loyalty/addloyalty/addloyalty.component';
import { AddwalletComponent } from './components/wallets/addwallet/addwallet.component';
import { UserroleComponent } from './components/userrole/userrole.component';
import { AdduserroleComponent } from './components/userrole/adduserrole/adduserrole.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AddsettingsComponent } from './components/settings/addsettings/addsettings.component';
import { PricechangeComponent } from './components/pricechange/pricechange.component';
import { AddpricechangeComponent } from './components/pricechange/addpricechange/addpricechange.component';
import { ReportComponent } from './components/report/report.component';
import { ReasonComponent } from './components/reason/reason.component';
import { AddreasonComponent } from './components/reason/addreason/addreason.component';
import { SettlementComponent } from './components/deliveryorder/settlement/settlement.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AddnotificationComponent } from './components/notification/addnotification/addnotification.component';
import { BranchComponent } from './components/branch/branch.component';
import { AddbranchComponent } from './components/branch/addbranch/addbranch.component';
import { AddtestComponent } from './components/test/addtest/addtest.component';
import { ActivitylogComponent } from './components/report/activitylog/activitylog.component';
import { RefundComponent } from './components/refund/refund.component';
import { AddrefundComponent } from './components/refund/addrefund/addrefund.component';
import { ProductgroupComponent } from './components/productgroup/productgroup.component';
import { AddproductgroupComponent } from './components/productgroup/addproductgroup/addproductgroup.component';
import { ProductgroupingComponent } from './components/productgrouping/productgrouping.component';
import { SalessummaryComponent } from './components/report/salessummary/salessummary.component';
import { SalesdetailssummaryComponent } from './components/report/salesdetailssummary/salesdetailssummary.component';
import { CartComponent } from './components/cart/cart.component';
import { AddcartComponent } from './components/cart/addcart/addcart.component';
import { CustomersearchComponent } from './components/customer/customersearch/customersearch.component';
import { SalesbybrandComponent } from './components/report/salesbybrand/salesbybrand.component';
import { SalesbyproductComponent } from './components/report/salesbyproduct/salesbyproduct.component';
import { SalesbycustomerComponent } from './components/report/salesbycustomer/salesbycustomer.component';
import { CancelledproductComponent } from './components/report/cancelledproduct/cancelledproduct.component';
import { ShipmenttypeComponent } from './components/shipmenttype/shipmenttype.component';
import { AddshipmenttypeComponent } from './components/shipmenttype/addshipmenttype/addshipmenttype.component';
import { NotifymeComponent } from './components/notifyme/notifyme.component';
import { PopularsearchComponent } from './components/popularsearch/popularsearch.component';
import { AddpopularsearchComponent } from './components/popularsearch/addpopularsearch/addpopularsearch.component';

const routes: Routes = [
  {path:'', component:DashboardComponent},
  {path:'Dashboard', component:DashboardComponent},
  {path:'Department', component:DepartmentComponent},
  {path:'AddDepartment', component:AdddepartmentComponent},
  {path:'Category', component:CategoryComponent},
  {path:'AddCategory', component:AddcategoryComponent},
  {path:'SubCategory', component:SubcategoryComponent},
  {path:'AddSubCategory', component:AddsubcategoryComponent},
  {path:'Brand', component:BrandComponent},
  {path:'AddBrand', component:AddbrandComponent},
  {path:'Uom', component:UomComponent},
  {path:'AddUom', component:AdduomComponent},
  {path:'Status', component:StatusComponent},
  {path:'AddStatus', component:AddstatusComponent},
  {path:'Warehouse', component:WarehouseComponent},
  {path:'AddWarehouse', component:AddwarehouseComponent},
  {path:'City', component:CityComponent},
  {path:'AddCity', component:AddcityComponent},
  {path:'Area', component:AreaComponent},
  {path:'AddArea', component:AddareaComponent},
  {path:'Vendor', component:VendorComponent},
  {path:'AddVendor', component:AddvendorComponent},
  {path:'BannerType', component:BannertypeComponent},
  {path:'AddBannerType', component:AddbannertypeComponent},
  {path:'Banner', component:BannerComponent},
  {path:'AddBanner', component:AddbannerComponent},
  {path:'Product', component:ProductComponent},
  {path:'AddProduct', component:AddproductComponent},
  {path:'TaxGroup', component:TaxgroupComponent},
  {path:'AddTaxGroup', component:AddtaxgroupComponent},
  {path:'FoodSymbol', component:FoodsymbolComponent},
  {path:'AddFoodSymbol', component:AddfoodsymbolComponent},
  {path:'Certificate', component:CertificateComponent},
  {path:'AddCertificate', component:AddcertificateComponent},
  {path:'ProductSection', component:ProductsectionComponent},
  {path:'AddProductSection', component:AddproductsectionComponent},
  {path:'Specification', component:SpecificationComponent},
  {path:'AddSpecification', component:AddspecificationComponent},
  {path:'DeliveryType', component:DeliverytypeComponent},
  {path:'AddDeliveryType', component:AdddeliverytypeComponent},
  {path:'ShipmentType', component:ShipmenttypeComponent},
  {path:'AddShipmentType', component:AddshipmenttypeComponent},
  {path:'DeliveryBoy', component:DeliveryboyComponent},
  {path:'AddDeliveryBoy', component:AdddeliveryboyComponent},
  {path:'DeliveryCharges', component:DeliverychargesComponent},
  {path:'AddDeliveryCharges', component:AdddeliverychargesComponent},
  {path:'DeliverySlot', component:DeliveryslotComponent},
  {path:'AddDeliverySlot', component:AdddeliveryslotComponent},
  {path:'Coupon', component:CouponComponent},
  {path:'AddCoupon', component:AddcouponComponent},
  {path:'Paymode', component:PaymodeComponent},
  {path:'AddPaymode', component:AddpaymodeComponent},
  {path:'Notification', component:NotificationComponent},
  {path:'AddNotification', component:AddnotificationComponent},
  {path:'Reason', component:ReasonComponent},
  {path:'AddReason', component:AddreasonComponent},
  {path:'Offer', component:OfferComponent},
  {path:'AddOffer', component:AddofferComponent},
  {path:'User', component:UserComponent},
  {path:'AddUser', component:AdduserComponent},
  {path:'Login', component:LoginComponent},
  {path:'Content', component:ContentmanagementComponent},
  {path:'AddContent', component:AddcontentmanagementComponent},
  {path:'Page', component:PageComponent},
  {path:'AddPage', component:AddpageComponent},
  {path:'Orders', component:OrdersComponent},
  {path:'AddOrders', component:AddordersComponent},
  {path:'AddOrderAddress', component:AddorderaddressComponent},
  {path:'DeliveryOrders', component:DeliveryorderComponent},  
  {path:'AddDeliveryOrders', component:AdddeliveryorderComponent},
  {path:'AddShipment', component:AddshipmentComponent},
  {path:'Settlement', component:SettlementComponent},
  {path:'Customer', component:CustomerComponent},
  {path:'CustomerView', component:ViewcustomerComponent},
  {path:'AddCustomer', component:AddcustomerComponent},
  {path:'AddAddress', component:AddaddressComponent},  
  {path:'Wallet', component:WalletsComponent},
  {path:'AddWallet', component:AddwalletComponent},
  {path:'Loyalty', component:LoyaltyComponent},
  {path:'AddLoyalty', component:AddloyaltyComponent},
  {path:'UserRole', component:UserroleComponent},
  {path:'AddUserRole', component:AdduserroleComponent},
  {path:'Settings', component:SettingsComponent},
  {path:'AddSettings', component:AddsettingsComponent},
  {path:'ProductTest', component:TestComponent},
  {path:'PriceChange', component:PricechangeComponent},
  {path:'AddPriceChange', component:AddpricechangeComponent},
  {path:'Report', component:ReportComponent},
  {path:'ActivityLog', component:ActivitylogComponent},
  {path:'Branch', component:BranchComponent},
  {path:'AddBranch', component:AddbranchComponent},
  {path:'Refund', component:RefundComponent},
  {path:'AddRefund', component:AddrefundComponent},
  {path:'ProductGroup', component:ProductgroupComponent},
  {path:'AddProductGroup', component:AddproductgroupComponent},
  {path:'ProductGrouping', component:ProductgroupingComponent},
  {path:'SalesSummaryReport', component:SalessummaryComponent},
  {path:'SalesDetailsSummaryReport', component: SalesdetailssummaryComponent },
  {path:'SalesByBrandReport', component: SalesbybrandComponent },
  {path:'SalesByProductReport', component: SalesbyproductComponent },
  { path: 'SalesByCustomerReport', component: SalesbycustomerComponent },
  { path: 'SalesByCustomerReport', component: SalesbycustomerComponent },
  { path: 'CancelledProductReport', component: CancelledproductComponent },
  
  {path:'Cart', component:CartComponent},
  {path: 'AddCart', component: AddcartComponent },
  {path:'CustomerSearch', component:CustomersearchComponent},
  
  {path:'Notifyme', component:NotifymeComponent},
  {path:'PopularSearch', component:PopularsearchComponent},
  { path: 'AddPopularSearch', component: AddpopularsearchComponent },
  
  {path:'Test', component:TestComponent},
  {path:'AddTest', component:AddtestComponent},
  

];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],  
  //imports: [RouterModule.forRoot(routes, { useHash: false })],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  //providers:[Location,{provide:LocationStrategy,useClass:PathLocationStrategy}]
})

export class AppRoutingModule { }
