import { BrowserModule } from '@angular/platform-browser';
import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import{ ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

  import { AppSettings } from './services/constants/constants';

//Additional 
//Model
import { NgbModule,NgbDate } from '@ng-bootstrap/ng-bootstrap';
//progress bar
import{ NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
//Toast Service
import { ToastrModule } from 'ngx-toastr';
//Select drop down
import { NgSelectModule } from '@ng-select/ng-select';
//Map
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'; 
//EDITOR
import { CKEditorModule } from 'ngx-ckeditor';
//Table
import { DataTablesModule } from 'angular-datatables';
//Date Pikker
import { BsDatepickerModule,TimepickerModule   } from 'ngx-bootstrap';
//Image Compress
import {NgxImageCompressService} from 'ngx-image-compress';
import { Ng2ImgMaxModule } from 'ng2-img-max';
//Decimal
import { NgxCurrencyModule } from "ngx-currency";
//Barcode
import { NgxBarcodeModule } from 'ngx-barcode';
//Sidebar
import { SidebarModule } from 'ng-sidebar';
//Device Info
import { DeviceDetectorModule } from 'ngx-device-detector';

//Firebase
// import {AngularFireModule} from 'angularfire2'
// import {AngularFireDatabaseModule} from 'angularfire2/database'
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

//Chart
import { NgxChartsModule } from '@swimlane/ngx-charts';

//Drag
import { DragDropModule } from '@angular/cdk/drag-drop';

//Ag Grid
import { AgGridModule } from 'ag-grid-angular';

import  {  NgxEmojiPickerModule  }  from  'ngx-emoji-picker';


import { NgxPaginationModule } from 'ngx-pagination'; 
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { NgxMaskModule, IConfig } from 'ngx-mask'


//Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { CategoryComponent } from './components/category/category.component';
import { AddcategoryComponent } from './components/category/addcategory/addcategory.component';
import { DepartmentComponent } from './components/department/department.component';

// service
import { DhukanDataService } from './services/dhukan/dhukan-data.service';
import { AdddepartmentComponent } from './components/department/adddepartment/adddepartment.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';
import { TestComponent } from './components/test/test.component';
import { AddtestComponent } from './components/test/addtest/addtest.component';
import { TextSearchPipePipe } from './text-search-pipe.pipe';
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { AddsubcategoryComponent } from './components/subcategory/addsubcategory/addsubcategory.component';
import { BrandComponent } from './components/brand/brand.component';
import { AddbrandComponent } from './components/brand/addbrand/addbrand.component';
import { UomComponent } from './components/uom/uom.component';
import { AdduomComponent } from './components/uom/adduom/adduom.component';
import { StatusComponent } from './components/status/status.component';
import { AddstatusComponent } from './components/status/addstatus/addstatus.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { AddwarehouseComponent } from './components/warehouse/addwarehouse/addwarehouse.component';
import { CityComponent } from './components/city/city.component';
import { AddcityComponent } from './components/city/addcity/addcity.component';
import { BannertypeComponent } from './components/bannertype/bannertype.component';
import { AddbannertypeComponent } from './components/bannertype/addbannertype/addbannertype.component';
import { BannerComponent } from './components/banner/banner.component';
import { AddbannerComponent } from './components/banner/addbanner/addbanner.component';
import { AreaComponent } from './components/area/area.component';
import { AddareaComponent } from './components/area/addarea/addarea.component';
import { VendorComponent } from './components/vendor/vendor.component';
import { AddvendorComponent } from './components/vendor/addvendor/addvendor.component';
import { ProductComponent } from './components/product/product.component';
import { AddproductComponent } from './components/product/addproduct/addproduct.component';
import { TaxgroupComponent } from './components/taxgroup/taxgroup.component';
import { AddtaxgroupComponent } from './components/taxgroup/addtaxgroup/addtaxgroup.component';
import { FoodsymbolComponent } from './components/foodsymbol/foodsymbol.component';
import { AddfoodsymbolComponent } from './components/foodsymbol/addfoodsymbol/addfoodsymbol.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { AddcertificateComponent } from './components/certificate/addcertificate/addcertificate.component';

import { TwoDigitDecimaNumberDirectiveDirective } from './two-digit-decima-number-directive.directive';
import { ProductfindComponent } from './components/productfind/productfind.component';
import { ProductsectionComponent } from './components/productsection/productsection.component';
import { AddproductsectionComponent } from './components/productsection/addproductsection/addproductsection.component';
import { SpecificationComponent } from './components/specification/specification.component';
import { AddspecificationComponent } from './components/specification/addspecification/addspecification.component';
import { ProductspecificationComponent } from './components/productspecification/productspecification.component';
import { ProductfaqComponent } from './components/productfaq/productfaq.component';
import { DeliverytypeComponent } from './components/deliverytype/deliverytype.component';
import { AdddeliverytypeComponent } from './components/deliverytype/adddeliverytype/adddeliverytype.component';
import { DeliveryboyComponent } from './components/deliveryboy/deliveryboy.component';
import { AdddeliveryboyComponent } from './components/deliveryboy/adddeliveryboy/adddeliveryboy.component';
import { DeliveryslotComponent } from './components/deliveryslot/deliveryslot.component';
import { AdddeliveryslotComponent } from './components/deliveryslot/adddeliveryslot/adddeliveryslot.component';
import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { LoginComponent } from './components/login/login.component';
import { ContentmanagementComponent } from './components/contentmanagement/contentmanagement.component';
import { AddcontentmanagementComponent } from './components/contentmanagement/addcontentmanagement/addcontentmanagement.component';
import { SectiontypeComponent } from './components/sectiontype/sectiontype.component';
import { OrdersComponent } from './components/orders/orders.component';
import { DateFormatPipe } from './date-format.pipe';
import { AddordersComponent } from './components/orders/addorders/addorders.component';
import { PageComponent } from './components/page/page.component';
import { AddpageComponent } from './components/page/addpage/addpage.component';
import { PagelayoutComponent } from './components/pagelayout/pagelayout.component';
import { PagelayoutimageComponent } from './components/pagelayoutimage/pagelayoutimage.component';
import { DeliveryorderComponent } from './components/deliveryorder/deliveryorder.component';
import { AdddeliveryorderComponent } from './components/deliveryorder/adddeliveryorder/adddeliveryorder.component';
import { AddshipmentComponent } from './components/deliveryorder/addshipment/addshipment.component';
import { DeliverychargesComponent } from './components/deliverycharges/deliverycharges.component';
import { AdddeliverychargesComponent } from './components/deliverycharges/adddeliverycharges/adddeliverycharges.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { AddcouponComponent } from './components/coupon/addcoupon/addcoupon.component';
import { PaymodeComponent } from './components/paymode/paymode.component';
import { AddpaymodeComponent } from './components/paymode/addpaymode/addpaymode.component';
import { OfferComponent } from './components/offer/offer.component';
import { AddofferComponent } from './components/offer/addoffer/addoffer.component';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DatetimeFormatPipe } from './datetime-format.pipe';
import { OrderaddressmapComponent } from './components/orderaddressmap/orderaddressmap.component';
import { AddorderaddressComponent } from './components/orders/addorderaddress/addorderaddress.component';
import { WalletsComponent } from './components/wallets/wallets.component';
import { LoyaltyComponent } from './components/loyalty/loyalty.component';
import { CustomerComponent } from './components/customer/customer.component';
import { AddcustomerComponent } from './components/customer/addcustomer/addcustomer.component';
import { ViewcustomerComponent } from './components/customer/viewcustomer/viewcustomer.component';
import { AddaddressComponent } from './components/customer/addaddress/addaddress.component';
import { AddwalletComponent } from './components/wallets/addwallet/addwallet.component';
import { AddloyaltyComponent } from './components/loyalty/addloyalty/addloyalty.component';
import { UserroleComponent } from './components/userrole/userrole.component';
import { AdduserroleComponent } from './components/userrole/adduserrole/adduserrole.component';
import { LoadingComponent } from './components/loading/loading.component';
import { UseraccessComponent } from './components/useraccess/useraccess.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AddsettingsComponent } from './components/settings/addsettings/addsettings.component';
import { DisablecontrolDirective } from './disablecontrol.directive';
import { PricechangeComponent } from './components/pricechange/pricechange.component';
import { AddpricechangeComponent } from './components/pricechange/addpricechange/addpricechange.component';
import { CancelorderComponent } from './components/orders/cancelorder/cancelorder.component';

import { HttpcancelService } from './services/dhukan/httpcancel.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManageHttpInterceptor } from './interceptor/managehttp.interceptor';
import { ProcessComponent } from './components/process/process.component';
import { EditordersComponent } from './components/orders/editorders/editorders.component';
import { ReportComponent } from './components/report/report.component';
import { PrintorderComponent } from './components/orders/printorder/printorder.component';
import { ReasonComponent } from './components/reason/reason.component';
import { AddreasonComponent } from './components/reason/addreason/addreason.component';
import { AdddeliveryoptionComponent } from './components/deliveryorder/adddeliveryoption/adddeliveryoption.component';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore/angular-fire-firestore';
import { SettlementComponent } from './components/deliveryorder/settlement/settlement.component';
import { DeliveryboyaddressmapComponent } from './components/deliveryboy/deliveryboyaddressmap/deliveryboyaddressmap.component';
import { PageorderComponent } from './components/page/pageorder/pageorder.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AddnotificationComponent } from './components/notification/addnotification/addnotification.component';
import { BranchComponent } from './components/branch/branch.component';
import { AddbranchComponent } from './components/branch/addbranch/addbranch.component';
import { ProductfilterComponent } from './components/product/productfilter/productfilter.component';
import { ActivitylogComponent } from './components/report/activitylog/activitylog.component';
import { StatusordersComponent } from './components/orders/statusorders/statusorders.component';
import { OrderoptionComponent } from './components/orders/orderoption/orderoption.component';
import { EditdeliverydateComponent } from './components/orders/editdeliverydate/editdeliverydate.component';
import { RefundComponent } from './components/refund/refund.component';
import { AddrefundComponent } from './components/refund/addrefund/addrefund.component';
import { ProductgroupComponent } from './components/productgroup/productgroup.component';
import { AddproductgroupComponent } from './components/productgroup/addproductgroup/addproductgroup.component';
import { ZoomproductComponent } from './components/orders/zoomproduct/zoomproduct.component';
import { ProductgroupingComponent } from './components/productgrouping/productgrouping.component';
import { SalessummaryComponent } from './components/report/salessummary/salessummary.component';
import { SalesdetailssummaryComponent } from './components/report/salesdetailssummary/salesdetailssummary.component';
import { CartComponent } from './components/cart/cart.component';
import { AddcartComponent } from './components/cart/addcart/addcart.component';
import { CustomeravaildateComponent } from './components/orders/customeravaildate/customeravaildate.component';
import { PagelayoutorderComponent } from './components/page/pagelayoutorder/pagelayoutorder.component';
import { ProductorderbyComponent } from './components/productsection/productorderby/productorderby.component';
import { CustomersearchComponent } from './components/customer/customersearch/customersearch.component';
import { AdderpinfoComponent } from './components/deliveryorder/adderpinfo/adderpinfo.component';
import { ImageformatterComponent } from './components/grid/imageformatter/imageformatter.component';
import { ButtonformatterComponent } from './components/grid/buttonformatter/buttonformatter.component';
import { ActionformatterComponent } from './components/grid/actionformatter/actionformatter.component';
import { SalesbybrandComponent } from './components/report/salesbybrand/salesbybrand.component';
import { SalesbyproductComponent } from './components/report/salesbyproduct/salesbyproduct.component';
import { SalesbycustomerComponent } from './components/report/salesbycustomer/salesbycustomer.component';
import { CancelledproductComponent } from './components/report/cancelledproduct/cancelledproduct.component';
import { CustomerperformanceComponent } from './components/report/customerperformance/customerperformance.component';
import { OfferproductComponent } from './components/offer/offerproduct/offerproduct.component';
import { EditproductmainComponent } from './components/product/editproductmain/editproductmain.component';
import { ShipmenttypeComponent } from './components/shipmenttype/shipmenttype.component';
import { AddshipmenttypeComponent } from './components/shipmenttype/addshipmenttype/addshipmenttype.component';
import { RenogridComponent } from './components/grid/renogrid/renogrid.component';
import { EmptystageComponent } from './components/grid/emptystage/emptystage.component';
import { SortDirective } from './sort.directive';
import { ExportgridComponent } from './components/grid/exportgrid/exportgrid.component';
import { NgMaskCusDirectiveDirective } from './ng_mask_cus-directive.directive';
import { NotifymeComponent } from './components/notifyme/notifyme.component';
import { PopularsearchComponent } from './components/popularsearch/popularsearch.component';
import { AddpopularsearchComponent } from './components/popularsearch/addpopularsearch/addpopularsearch.component';
import { EditproductskuComponent } from './components/product/editproductsku/editproductsku.component';
import { ConfirmorderComponent } from './components/orders/confirmorder/confirmorder.component';
import { ReadytopickorderComponent } from './components/orders/readytopickorder/readytopickorder.component';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: "",
  separatorLimit: "9999999999",
  allowNegativeNumbers: false,
  dropSpecialCharacters: false,
  patterns: {
    'V': { pattern: new RegExp('-?') },
    '0': { pattern: new RegExp('[0-9]') }
  }
};




@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavigationComponent,
    HeaderComponent,
    CategoryComponent,
    AddcategoryComponent,
    DepartmentComponent,
    AdddepartmentComponent,
    ConfirmationDialogComponent,
    TestComponent,
    AddtestComponent,
    TextSearchPipePipe,
    SubcategoryComponent,
    AddsubcategoryComponent,
    BrandComponent,
    AddbrandComponent,
    UomComponent,
    AdduomComponent,
    StatusComponent,
    AddstatusComponent,
    WarehouseComponent,
    AddwarehouseComponent,
    CityComponent,
    AddcityComponent,
    BannertypeComponent,
    AddbannertypeComponent,
    BannerComponent,
    AddbannerComponent,
    AreaComponent,
    AddareaComponent,
    VendorComponent,
    AddvendorComponent,
    ProductComponent,
    AddproductComponent,
    TaxgroupComponent,
    AddtaxgroupComponent,
    FoodsymbolComponent,
    AddfoodsymbolComponent,
    CertificateComponent,
    AddcertificateComponent,
    TwoDigitDecimaNumberDirectiveDirective,
    ProductfindComponent,
    ProductsectionComponent,
    AddproductsectionComponent,
    SpecificationComponent,
    AddspecificationComponent,
    ProductspecificationComponent,
    ProductfaqComponent,
    DeliverytypeComponent,
    AdddeliverytypeComponent,
    DeliveryboyComponent,
    AdddeliveryboyComponent,
    DeliveryslotComponent,
    AdddeliveryslotComponent,
    UserComponent,
    AdduserComponent,
    LoginComponent,
    ContentmanagementComponent,
    AddcontentmanagementComponent,
    SectiontypeComponent,
    OrdersComponent,
    DateFormatPipe,
    AddordersComponent,
    PageComponent,
    AddpageComponent,
    PagelayoutComponent,
    PagelayoutimageComponent,
    DeliveryorderComponent,
    AdddeliveryorderComponent,
    AddshipmentComponent,
    DeliverychargesComponent,
    AdddeliverychargesComponent,
    CouponComponent,
    AddcouponComponent,
    PaymodeComponent,
    AddpaymodeComponent,
    OfferComponent,
    AddofferComponent,
    DatetimeFormatPipe,
    OrderaddressmapComponent,
    AddorderaddressComponent,
    WalletsComponent,
    LoyaltyComponent,
    CustomerComponent,
    AddcustomerComponent,
    ViewcustomerComponent,
    AddaddressComponent,
    AddwalletComponent,
    AddloyaltyComponent,
    UserroleComponent,
    AdduserroleComponent,
    LoadingComponent,
    UseraccessComponent,
    SettingsComponent,
    AddsettingsComponent,
    DisablecontrolDirective,
    PricechangeComponent,
    AddpricechangeComponent,
    CancelorderComponent,
    ProcessComponent,
    EditordersComponent,
    ReportComponent, 
    SortDirective,
    PrintorderComponent, ReasonComponent, AddreasonComponent, AdddeliveryoptionComponent, SettlementComponent, DeliveryboyaddressmapComponent, PageorderComponent, NotificationComponent, AddnotificationComponent, BranchComponent, AddbranchComponent, ProductfilterComponent, ActivitylogComponent, StatusordersComponent, OrderoptionComponent, EditdeliverydateComponent, RefundComponent, AddrefundComponent, ProductgroupComponent, AddproductgroupComponent, ZoomproductComponent, ProductgroupingComponent, SalessummaryComponent, SalesdetailssummaryComponent, CartComponent, AddcartComponent, CustomeravaildateComponent, PagelayoutorderComponent, ProductorderbyComponent, CustomersearchComponent, AdderpinfoComponent, ImageformatterComponent, ButtonformatterComponent, ActionformatterComponent, SalesbybrandComponent, SalesbyproductComponent, SalesbycustomerComponent, CancelledproductComponent, CustomerperformanceComponent, OfferproductComponent, EditproductmainComponent, ShipmenttypeComponent, AddshipmenttypeComponent, RenogridComponent, EmptystageComponent, ExportgridComponent,
    NgMaskCusDirectiveDirective,
    NotifymeComponent,
    PopularsearchComponent,
    AddpopularsearchComponent,
    EditproductskuComponent,
    ConfirmorderComponent,
    ReadytopickorderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,  
    ReactiveFormsModule,
    NgbModule,    
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    NgSelectModule,  
    DragDropModule,
    CKEditorModule,
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    Ng2ImgMaxModule,
    NgxBarcodeModule,
    SidebarModule.forRoot(),
    DeviceDetectorModule.forRoot(),   
    NgxEmojiPickerModule.forRoot(),
    NgxPaginationModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    Ng2SearchPipeModule,
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-top-center'
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }), 
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyClUICP4-qGf2r4SSCZF5MzbSFXT6mIbvM",
      //apiKey: "AIzaSyCUreeeYOumHHYh90WbM2kxNrfyYfGn3V0",
      libraries: ["places"]
     }),
     NgxChartsModule,
     AgmDirectionModule,
     AngularFireModule.initializeApp(AppSettings.firebaseConfig),
     AngularFireDatabaseModule,
     AgGridModule.withComponents([ImageformatterComponent,ButtonformatterComponent])
  ],
  schemas:[NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe,NgxImageCompressService,DateFormatPipe, Location],
  bootstrap: [AppComponent],
  exports: [BrowserModule, TranslateModule],
  entryComponents: [ ConfirmationDialogComponent,ProductfindComponent,ProductspecificationComponent,ProductfaqComponent,SectiontypeComponent,
    PagelayoutComponent,PagelayoutimageComponent,OrderaddressmapComponent,CancelorderComponent,EditordersComponent,PrintorderComponent,
    AdddeliveryoptionComponent, DeliveryboyaddressmapComponent, PageorderComponent, ProductfilterComponent,ConfirmorderComponent,ReadytopickorderComponent,
    OrderoptionComponent, EditdeliverydateComponent, ZoomproductComponent, CustomeravaildateComponent, PagelayoutorderComponent, AdderpinfoComponent, ActionformatterComponent,
    ProductorderbyComponent,OfferproductComponent,EditproductmainComponent,RenogridComponent,EmptystageComponent,ExportgridComponent,AddsettingsComponent,EditproductskuComponent],
})
export class AppModule { }

// { provide:HTTP_INTERCEPTORS, useClass:ManageHttpInterceptor,multi:true},
