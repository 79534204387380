import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit,AfterViewInit, OnDestroy {


  couponColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Coupon";
  
  searchtxt: any = "";
  columnDefs = [
    { headerName: 'CouponCode', field: 'couponcode' },
    { headerName: 'CouponName', field: 'couponname' },
  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };


  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService ,private productFindService: NgbModal)
     {   this.fnServiceChanges(); }


    //User Check
    fnServiceChanges(){
      this.loginService.serviceloginChange.subscribe((value) => {
            if(this.loginService.fnCheckUser()){                
                this.fnCheckPrivilage();
                if(this.isview){
                  this.isloading=true;
                  this.fnOnInitForm();
                }
                else{
                  this.fnUserFailedForm();
                }    
            }
            else{
              this.fnUserFailedForm();
            }
      });
    }


  ngOnInit() {
    try{     
     

    if(this.loginService.fnCheckUser()){         
      this.fnCheckPrivilage();
      if(this.isview){
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }          
    }
    else{
      this.loginService.fnGetUserRoleList();
    }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }
       
  fnOnInitForm(){
    this.fngetCoupon();     
  }
  

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }

  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
   
  }
  
  fngetCoupon() {   

    if(this.showProgress){
      return;
    }


    this.fnShowProgress();
      var data = {
            'couponid': 0,            
      }
      this.appService.getCoupon(data)
          .subscribe(
            (res)=>{    

              this.fnHideProgress();
              if(res.json().status==200){     
                              
                this.couponColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddCoupon(){
    try{
      this.router.navigate(['/AddCoupon']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditCoupon(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddCoupon'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Coupon?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteCoupon(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteCoupon(deptid){
       var data = {
         'deliveryboyid': deptid,            
       }
      this.appService.fnDeleteCoupon(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The Coupon has been deleted successfully.");     
             
             this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
             
              this.fngetCoupon();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

       //Grid


  fnActionEditClick(data) {

    if (!this.isedit) {
      return;
    }
    this.fnEditCoupon(data.couponid);
    
  }

  fnActionDeleteClick(data) {
  
    if (!this.isdelete) {
      return;
    }


    this.fnDeleteConfirm(data.couponid);
  }

  fnActionExportClick() {
    
  const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
  modalRef.componentInstance.doctype = this.formname;
  modalRef.componentInstance.columnDefs = this.columnDefs;
  modalRef.componentInstance.contentColl = this.couponColl;
  modalRef.componentInstance.fnSetTable();
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

  
  });
  }


 private fnSetDataTable() {
   
  }


  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }

    
  fnPrintData(){
    try{
                          
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  


fnGetDescription(id){
  const index = this.couponColl.findIndex(sku => sku.couponid === id  ); 

  if(index){
    return this.couponColl[index].couponname;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}


  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }

  
  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }




}
