import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';


@Component({
  selector: 'app-exportgrid',
  templateUrl: './exportgrid.component.html',
  styleUrls: ['./exportgrid.component.css']
})
export class ExportgridComponent implements OnInit {

  @Input() doctype: string = ''
  @Input() columnDefs: any = []
  @Input() contentColl: any = []

  tempcolumnDefs: any = []

  @Output() emitData = new EventEmitter();


  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
  };

  constructor( private activeModal: NgbActiveModal,public loginService:LoginDataService) { }

  ngOnInit() {

  }

  fnSetTable() {

    this.tempcolumnDefs=this.columnDefs;

    this.gridOptions = {
      enableactioncol: false,
      columnDefs: this.tempcolumnDefs,
    };

    for (let row of this.columnDefs) {
      row.ischeck = true
    }

  }



  fnCheckColumn(){
    this.tempcolumnDefs=[]

    for (let row of this.columnDefs) {
      if(row.ischeck){
        this.tempcolumnDefs.push(row)
      }
      
    }

    this.gridOptions = {
      enableactioncol: false,
      columnDefs: this.tempcolumnDefs,
    };

  }
  public decline() {
    this.activeModal.close(false);
  }


}
