import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';
declare var $ :any;

@Component({
  selector: 'app-deliverytype',
  templateUrl: './deliverytype.component.html',
  styleUrls: ['./deliverytype.component.css']
})
export class DeliverytypeComponent implements OnInit ,AfterViewInit, OnDestroy {
 

  deliverytypeColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="DeliveryType";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Delivery Type', field: 'deliverytype' },

  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };


  


  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,
    private loginService:LoginDataService, private productFindService: NgbModal)    
    { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }  
   


  ngOnInit() {
    try{
    
        if(this.loginService.fnCheckUser()){         
          this.fnCheckPrivilage();
          if(this.isview){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }          
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetDeliveryType();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
ngAfterViewInit(): void {
 
}

ngOnDestroy(): void {
 
}

fngetDeliveryType() {  
    
    if(this.showProgress){
      return;
    }
    this.fnShowProgress();
      var data = {
            'deliverytypeid': 0,            
      }
      this.appService.getDeliveryType(data)
          .subscribe(
            (res)=>{                  
              this.fnHideProgress();       
              if(res.json().status==200){               
                     
                this.deliverytypeColl=res.json().result;                
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{      
              this.fnHideProgress();           
              this.toastr.error('Error', err);
            })
  }

  fnAddDeliveryType(){
    try{
      this.router.navigate(['/AddDeliveryType']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditDeliveryType(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddDeliveryType'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete DeliveryType?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteDeliveryType(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteDeliveryType(deptid){
       var data = {
         'deliverytypeid': deptid,            
       }
      this.appService.fnDeleteDeliveryType(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
              this.toastr.success('', " The DeliveryType has been deleted successfully.");                 

              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));

              this.fngetDeliveryType();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  private fnSetDataTable(){
    
  }


  //Grid



  fnActionEditClick(data) {
 

    if (!this.isedit) {
      return;
    }
    this.fnEditDeliveryType(data.deliverytypeid);
    
  }

  fnActionDeleteClickdata(data) {
   

    if (!this.isdelete) {
      return;
    }

    this.fnDeleteConfirm(data.deliverytypeid);
   
  }

  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.deliverytypeColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });
  }


       
  fnPrintData(){
    try{
    
                          
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  
  
  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }

fnGetDescription(id){
  const index = this.deliverytypeColl.findIndex(sku => sku.deliverytypeid === id  ); 

  if(index){
    return this.deliverytypeColl[index].deliverytype;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}



  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }



}
