import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';
declare var $: any;

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit, AfterViewInit, OnDestroy {

  cityColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "City";


  searchtxt: any = "";
  columnDefs = [
    { headerName: 'City Name', field: 'cityname',  },
    { headerName: 'Warehouse', field: 'warehousename',  },
  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };


  



  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, 
    private loginService: LoginDataService, private productFindService: NgbModal) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnOnInitForm() {
    this.fngetCity();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  fngetCity() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'cityid': 0,
      'warehouseid': 0
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.cityColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }

        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddCity() {
    try {
      this.router.navigate(['/AddCity']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditCity(id) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': id,
        }
      }

      this.router.navigate(['/AddCity'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(id) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete city?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteCity(id)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteCity(id) {
    var data = {
      'cityid': id,
    }
    this.appService.fnDeleteCity(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The city has been deleted successfully.");

            this.fnInsertActivityLog('DELETE', id, this.fnGetDescription(id));

            this.fngetCity();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Grid
  


  fnActionEditClick(data) {
 

    if (!this.isedit) {
      return;
    }
    this.fnEditCity(data.cityid);
   
  }

  fnActionDeleteClick(data) {


    if (!this.isdelete) {
      return;
    }

    this.fnDeleteConfirm(data.cityid);
   
  }

  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.cityColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });

  }


  private fnSetDataTable() {

  }

  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }

  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnGetDescription(id) {
    const index = this.cityColl.findIndex(sku => sku.cityid === id);

    if (index) {
      return this.cityColl[index].cityname;
    }
    else {
      return "";
    }

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }




  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
