import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryboyaddressmapComponent } from './deliveryboyaddressmap/deliveryboyaddressmap.component';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';
declare var $ :any;

@Component({
  selector: 'app-deliveryboy',
  templateUrl: './deliveryboy.component.html',
  styleUrls: ['./deliveryboy.component.css']
})
export class DeliveryboyComponent implements OnInit ,AfterViewInit, OnDestroy {
 

  deliveryboyColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="DeliveryBoy";


  
searchtxt: any = "";
columnDefs = [
  { headerName: 'Code', field: 'deliveryboycode',  },
  { headerName: 'Name', field: 'deliveryboyname',  },
  { headerName: 'Mobile', field: 'mobile',  },
  { headerName: 'Userid', field: 'userid',  },
]
gridOptions = {
  enableactioncol: true,
  columnDefs: this.columnDefs,
  pageindex: 1
};

 


  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,
    private loginService:LoginDataService, private productFindService: NgbModal)
   { this.fnServiceChanges(); }

    //User Check
    fnServiceChanges(){
      this.loginService.serviceloginChange.subscribe((value) => {
            if(this.loginService.fnCheckUser()){                
                this.fnCheckPrivilage();
                if(this.isview){
                  this.isloading=true;
                  this.fnOnInitForm();
                }
                else{
                  this.fnUserFailedForm();
                }    
            }
            else{
              this.fnUserFailedForm();
            }
      });
    }
    
 

  ngOnInit() {
    try{
         
    

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetDeliveryBoy();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
   
  }

  ngOnDestroy(): void {
    
  }
  
  fngetDeliveryBoy() {   
    if(this.showProgress){
      return;
    }
    this.fnShowProgress();
      var data = {
            'deliveryboyid': 0,            
      }
      this.appService.getDeliveryBoy(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();            
              if(res.json().status==200){                          
                this.deliveryboyColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddDeliveryBoy(){
    try{
    
           
      this.router.navigate(['/AddDeliveryBoy']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditDeliveryBoy(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddDeliveryBoy'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete DeliveryBoy?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteDeliveryBoy(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteDeliveryBoy(deptid){
       var data = {
         'deliveryboyid': deptid,            
       }
      this.appService.fnDeleteDeliveryBoy(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The DeliveryBoy has been deleted successfully.");                 

              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
              
              this.fngetDeliveryBoy();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  fnGeoLocationDeliveryBoy(dbBO){
  
  
    const modalRef = this.productFindService.open(DeliveryboyaddressmapComponent ,{ size: <any>'xl'  });
    modalRef.componentInstance.doctype ="COMBO PRODUCT" ;
    modalRef.componentInstance.deliveryboymaptype ="SINGLE" ;    
    modalRef.componentInstance.latitude =dbBO.latitude ;
    modalRef.componentInstance.longitude =dbBO.longitude ;
    modalRef.componentInstance.deliveryboyname =dbBO.deliveryboyname ;
    modalRef.componentInstance.deliveryboyuserid =dbBO.userid ;
    modalRef.componentInstance.deliveryboyid =dbBO.deliveryboyid ;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
      
    });
  
  
  }

  
  //Grid


  fnActionEditClick(data) {
  

    if (!this.isedit) {
      return;
    }
    this.fnEditDeliveryBoy(data.deliveryboyid);
    
  }

  fnActionDeleteClick(data) {
    

    if (!this.isdelete) {
      return;
    }

    this.fnDeleteConfirm(data.deliveryboyid);
   
  }

  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.deliveryboyColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });
  }


       
  private fnSetDataTable(){
   
  }
  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }


  fnPrintData(){
    try{
    
                        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
                        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportPdfData(){
    try{
                        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }


  fnGetDescription(id){
    const index = this.deliveryboyColl.findIndex(sku => sku.deliveryboyid === id  ); 

    if(index){
      return this.deliveryboyColl[index].deliveryboyname;
    }
    else{
      return "";
    }

}
  
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }

  
  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }


}
