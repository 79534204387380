import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ImageformatterComponent } from '../grid/imageformatter/imageformatter.component';
import * as moment from 'moment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit  ,AfterViewInit, OnDestroy {
 

  notificationColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Notification";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Name', field: 'notificationname'},
    { headerName: 'Title', field: 'title' },
    { headerName: 'Message', field: 'notificationmsg'},        
    { headerName: 'UpdateDate', field: 'modifiedon' },
  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };
  

  

  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService  ,private productFindService: NgbModal) { this.fnServiceChanges(); }

   //User Check
   fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
  
     

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetNotification();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
  
  }
  
  
  
  //Grid

  
  //Event  
  fnActionEditClick(data) {
    this.fnEditNotification(data.notificationid);
  }

  fnActionDeleteClick(data) {
    this.fnDeleteConfirm(data.notificationid); 
  }

  fnActionExportClick()  {
     
  const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
  modalRef.componentInstance.doctype = this.formname;
  modalRef.componentInstance.columnDefs = this.columnDefs;
  modalRef.componentInstance.contentColl = this.notificationColl;
  modalRef.componentInstance.fnSetTable();
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

  
  });
  }



  fngetNotification() { 
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
      var data = {
            'notificationid': 0,            
      }
      this.appService.getNotification(data)
          .subscribe(
            (res)=>{             
              
              this.fnHideProgress();
              if(res.json().status==200){                          
                this.notificationColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddNotification(){
    try{
      this.router.navigate(['/AddNotification']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditNotification(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddNotification'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Notification?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteNotification(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteNotification(deptid){
       var data = {
         'notificationid': deptid,            
       }
      this.appService.fnDeleteNotification(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The Notification has been deleted successfully.");                 

             this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
             
              this.fngetNotification();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

     
  private fnSetDataTable(){
   
  }


  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }

fnGetDescription(id){
  const index = this.notificationColl.findIndex(sku => sku.notificationid === id  ); 

  if(index){
    return this.notificationColl[index].title;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}


fnMomentDateFormatter(params) {
  if (params.value) {
    return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
  }
  else {
    return '';
  }
}

fnMomentDateTimeFormatter(params) {
  if (params.value) {
    return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
  }
  else {
    return '';
  }
  
}
  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }





}
