import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  offerallColl:any=[];
  offerColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname: String = "Offer";
  
  filterby:String="ACTIVE";

  
  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Offer Code', field: 'offercode',},
    { headerName: 'Offer Name', field: 'offername'},
    { headerName: 'Offer Type', field: 'offertype' },
    { headerName: 'Offer From', field: 'offerfrom'},
    { headerName: 'Offer To', field: 'offerto' },
    { headerName: 'Update On', field: 'modifiedon'},        
  ]
  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
    pageindex: 1
  };



  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private productFindService: NgbModal ,private loginService:LoginDataService) 
    {  this.fnServiceChanges(); }

  //User Check
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  } 
 




  ngOnInit() {
    try{
         
    
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
      

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetOfferListView();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
 
  }

  ngOnDestroy(): void {
  
  }
  
  

  //Grid
  
  
  //Event  
  fnActionEditClick(data) {
    this.fnEditOffer(data.offerid);  
  }

  fnActionDeleteClick(data) {
    this.fnDeleteConfirm(data.offerid);
    
  }

  fnActionExportClick()  {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.offerColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    });
  }


  fngetOfferListView() {   

    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
      var data = {
        
      }
      this.appService.getOfferListView(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();                   
              if(res.json().status==200){                          
                this.offerallColl = res.json().result;
                
                this.offerColl = [];
             
                  for (let abo of this.offerallColl) {
                    if (this.filterby === 'ACTIVE') { 
                      if (abo.ischeck === 1) {
                        this.offerColl.push(abo)
                      }                        
                    }
                    else if (this.filterby === 'INACTIVE') { 
                      if (abo.ischeck === 0) {
                        this.offerColl.push(abo)
                      }  
                    }
                    else{
                      this.offerColl.push(abo)
                    }
                  }
                

                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }


  fnAddOffer(){
    try{
        
  
      this.router.navigate(['/AddOffer']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

 
 
  fnEditOffer(deptid){
    try{
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': deptid,
          }
        }
  
      this.router.navigate(['/AddOffer'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Offer?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteOffer(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteOffer(deptid){
       var data = {
         'offerid': deptid,            
       }
      this.appService.fnDeleteOffer(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
              this.toastr.success('', " The Offer has been deleted successfully.");                 

              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));

              this.fngetOfferListView();
            }else{
              this.toastr.error('',res.json().err_field);   
          }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

  private fnSetDataTable() {
    
      
  }

  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }

  
  fnGetDescription(id){
    const index = this.offerColl.findIndex(sku => sku.offerid === id  ); 

    if(index){
      return this.offerColl[index].offername;
    }
    else{
      return "";
    }

}

  
 fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


fnMomentDateFormatter(params) {
  return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
}

fnMomentDateTimeFormatter(params) {
  return moment(params.value).format('DD-MM-YYYY hh:mm a');//
}
  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }




}
