import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';

@Component({
  selector: 'app-notifyme',
  templateUrl: './notifyme.component.html',
  styleUrls: ['./notifyme.component.css']
})
export class NotifymeComponent implements OnInit {

  
 
  walletColl:any=[];


  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Wallet";


 searchtxt: any = "";
 columnDefs = [
  { headerName: 'Pic', field: 'picurl',type:'pic' },
  { headerName: 'Sku', field: 'sku'},  
  { headerName: 'Name', field: 'productname'},     
  { headerName: 'Customer Name', field: 'customername' },
  { headerName: 'Mobile', field: 'mobile'},
  { headerName: 'Email', field: 'email' },
  { headerName: 'Send On', field: 'createdon',type:'datetime' },
 
 ]
 gridOptions = {
   enableactioncol: false,
   columnDefs: this.columnDefs,
   pageindex: 1
 };
 
 
  

  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService ,private loginService:LoginDataService  ,private productFindService: NgbModal)
     { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  } 





  ngOnInit() {
    try{

 
  
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

   
  fnOnInitForm(){
    this.fngetNotifymeListView();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
   
  }

  ngOnDestroy(): void {
    
  }
  
  fngetNotifymeListView() { 
    if(this.showProgress){
      return;
    }

    
    this.fnShowProgress();
      var data = {
        
      }
      this.appService.getNotifyme(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();            
              if(res.json().status==200){                          
                this.walletColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }


  //Grid

  fnActionExportClick() {
   
  const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
  modalRef.componentInstance.doctype = this.formname;
  modalRef.componentInstance.columnDefs = this.columnDefs;
  modalRef.componentInstance.contentColl = this.walletColl;
  modalRef.componentInstance.fnSetTable();
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

  
  });
  
  }


  private fnSetDataTable(){
 
  }


  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }
      
  fnPrintData(){
    try{
    
                      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  

  

  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }






}
