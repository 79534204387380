import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'jspdf';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { OrderaddressmapComponent } from '../../orderaddressmap/orderaddressmap.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { CancelorderComponent } from '../cancelorder/cancelorder.component';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { EditordersComponent } from '../editorders/editorders.component';
import { PrintorderComponent } from '../printorder/printorder.component';
import { DatePipe } from '@angular/common';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker/models';
import { OrderoptionComponent } from '../orderoption/orderoption.component';
import { EditdeliverydateComponent } from '../editdeliverydate/editdeliverydate.component';
import { ZoomproductComponent } from '../zoomproduct/zoomproduct.component';
import { CustomeravaildateComponent } from '../customeravaildate/customeravaildate.component';
import { AdddeliveryoptionComponent } from '../../deliveryorder/adddeliveryoption/adddeliveryoption.component';
import { ConfirmorderComponent } from '../confirmorder/confirmorder.component';
import { ReadytopickorderComponent } from '../readytopickorder/readytopickorder.component';
declare let jsPDF;

@Component({
  selector: 'app-addorders',
  templateUrl: './addorders.component.html',
  styleUrls: ['./addorders.component.css']
})
export class AddordersComponent implements OnInit {
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  statusColl: any = [];

  ordermainColl: any = [];
  ordermainBo: any = {};
  orderdetailsColl: any = [];
  orderpayColl: any = [];
  orderpayBo: any = {};
  orderaddressColl: any = [];
  orderaddressBo: any = {};
  orderid: number = 0;
  customerid: number = 0;
  statusid: number = 0;

  isorderedit: boolean = false;
  isorderconfirm: boolean = false;
  isordershipment: boolean = false;
  isprintdoslip: boolean = false;
  isprintorder: boolean = false;

  isorderassign: boolean = false;
  isorderreadytopick: boolean = false;
  isorderpick: boolean = false;
  isorderdeliver: boolean = false;
  isorderreceived: boolean = false;
  isdeleteshipment: boolean = false;

  isshowmoreinfo: boolean = false;

  dateCustomClasses: DatepickerDateCustomClasses[];

  deliveryorderBO: any = {};

  deliveryorderColl: any = [];
  deliveryorderdetailscoll: any = [];
  deliveryorderdetailallcoll: any = [];
  deliverytypeColl: any = [];
  branchColl: any = [];
  shipmenttypeColl: any = [];

  shipmentdetailscoll: any = [];

  subtotal = 0;
  discountamount = 0;
  taxableamt = 0;
  taxamt = 0;
  nettotal = 0;

  distance: any;

  orderform = new FormGroup({
    orderno: new FormControl(''),
    orderid: new FormControl(0),
    orderdate: new FormControl(''),
    customername: new FormControl(''),
    mobileno: new FormControl(''),
    addressnickname: new FormControl(''),
    houseno: new FormControl(''),
    street: new FormControl(''),
    areano: new FormControl(''),
    cityname: new FormControl(''),
    landmark: new FormControl(''),
    statusid: new FormControl(0),
    status: new FormControl(''),
  });

  orderconfirmform = new FormGroup({
    deliverytypeid: new FormControl(null, Validators.required),
    expectdeliverydate: new FormControl(null, Validators.required),
    expectdeliverytime: new FormControl(null, Validators.required),
    branchid: new FormControl(null, Validators.required),
  })


  shipmentreadyform = new FormGroup({
    trackno: new FormControl('', Validators.required),
    shipmenttypeid: new FormControl(null, Validators.required),
  })

  @ViewChild('reportContent', { static: false }) reportContent: ElementRef;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Order";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private confirmationDialogService: ConfirmationDialogService,
    private productFindService: NgbModal, private loginService: LoginDataService, private datePipe: DatePipe) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.orderid = params.id
      }

      if (params.cid !== undefined) {
        this.customerid = params.cid
      }



    });

    this.dateCustomClasses = [
      { date: new Date(), classes: ['bg-warning'] },
    ];

    this.fnServiceChanges();
  }

  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }



  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.orderform.reset();
      this.fnInitializeStatusFormGroup();
      this.fngetDeliveryType();
      this.fngetBranch();
      this.fngetStatus();
      this.fngetShipmentType()

      if (this.orderid > 0) {
        this.fngetOrder();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.orderid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    }


    this.isorderedit = this.loginService.fnCheckUserRoleAction("Order", 'ALTER')
    this.isorderconfirm = this.loginService.fnCheckUserRoleAction("OrderConfirm", 'ALTER')
    this.isordershipment = this.loginService.fnCheckUserRoleAction("Shipment", 'CREATE')

    this.isprintdoslip = this.loginService.fnCheckUserRoleAction("DOPrint", 'ALTER')
    this.isprintorder = this.loginService.fnCheckUserRoleAction("OrderPrint", 'ALTER')

    this.isdeleteshipment = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE')
    this.isorderassign = this.loginService.fnCheckUserRoleAction("ShipmentAssign", 'ALTER');
    this.isorderreadytopick = this.loginService.fnCheckUserRoleAction("ShipmentReadyToPick", 'ALTER');
    this.isorderpick = this.loginService.fnCheckUserRoleAction("ShipmentPick", 'ALTER');
    this.isorderdeliver = this.loginService.fnCheckUserRoleAction("ShipmentDeliver", 'ALTER');
    this.isorderreceived = this.loginService.fnCheckUserRoleAction("Settlement", 'ALTER');

    if (this.isorderconfirm && !_check) {
      _check = this.isorderconfirm;
    }
    else if (this.isordershipment && !_check) {
      _check = this.isordershipment;
    }


    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  fnInitializeStatusFormGroup() {
    this.orderform.setValue({
      orderno: '',
      orderid: 0,
      orderdate: '',
      customername: '',
      mobileno: '',
      addressnickname: '',
      houseno: '',
      street: '',
      areano: '',
      cityname: '',
      landmark: '',
      statusid: null,
      status: '',
    })

    this.orderconfirmform.setValue({
      deliverytypeid: null,
      expectdeliverydate: null,
      expectdeliverytime: null,
      branchid: null,
    })

    this.shipmentreadyform.setValue({
      trackno: '',
      shipmenttypeid: null,
    })
  }

  fngetDeliveryType() {

    var data = {
      'deliverytypeid': 0,
    }
    this.appService.getDeliveryType(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.deliverytypeColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fngetShipmentType() {

    var data = {
      'shipmenttypeid': 0,
    }
    this.appService.getShipmentType(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.shipmenttypeColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fngetBranch() {

    var data = {
      'branchid': 0,
    }
    this.appService.getBranch(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.branchColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  fngetOrder() {
    this.fnShowProgress()
    var data = {
      'orderid': this.orderid,
    }
    this.appService.getOrderbyOrderid(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          this.ordermainColl = res.json().result[0];
          this.orderdetailsColl = res.json().result[1];
          this.orderpayColl = res.json().result[2];
          this.orderaddressColl = res.json().result[3];
          this.deliveryorderColl = res.json().result[4];
          this.deliveryorderdetailallcoll = res.json().result[5];

          this.ordermainBo = this.ordermainColl[0]

          if (this.orderaddressColl.length) {
            for (let abo of this.orderaddressColl) {
              if (abo.addresstype === 'D') {
                this.orderaddressBo = abo;

                this.distance = this.getDistanceFromLatLonInKm(this.orderaddressBo.latitude, this.orderaddressBo.longitude, this.orderaddressBo.arealatitude, this.orderaddressBo.arealongitude).toFixed(1);

              }
            }
          }

          if (this.orderpayColl.length) {
            this.orderpayBo = this.orderpayColl[0];
          }
          if (this.deliveryorderColl.length) {
            this.deliveryorderBO = this.deliveryorderColl[0];
          }
          else {
            this.deliveryorderBO = this.ordermainBo;
          }



          var editdata = {
            orderno: this.ordermainColl[0].orderno,
            orderid: this.ordermainColl[0].orderid,
            orderdate: this.ordermainColl[0].orderdate,
            customername: this.ordermainColl[0].customername,
            mobileno: this.ordermainColl[0].mobile,
            addressnickname: this.ordermainColl[0].addressnickname,
            houseno: this.ordermainColl[0].houseno,
            street: this.ordermainColl[0].street,
            areano: this.ordermainColl[0].areaname,
            cityname: this.ordermainColl[0].cityname,
            landmark: this.ordermainColl[0].landmark,
            statusid: this.ordermainColl[0].statusid,
            status: this.ordermainColl[0].status,
          }
          this.fnEditMode(editdata);

          let _a: any = []
          for (let abo of this.deliveryorderColl) {
            abo.deliveryorderdetailscoll = _a;
          }


          for (let abo1 of this.deliveryorderColl) {
            let _dcoll: any = []

            for (let abo2 of this.deliveryorderdetailallcoll) {
              if (abo1.deliveryorderid === abo2.deliveryorderid) {
                _dcoll.push(abo2);

              }
            }
            abo1.deliveryorderdetailscoll = _dcoll;
          }

          this.fnCalcSummary();


        },
        (err) => {
          this.fnHideProgress()
          this.toastr.error('Error', err);
        })
  }

  fnCalcSummary() {
    this.subtotal = 0;
    this.discountamount = 0;
    this.taxableamt = 0;
    this.taxamt = 0;
    this.nettotal = 0;

    for (let abo of this.orderdetailsColl) {

      if (abo.iscancelled) {
        continue;
      }

      this.subtotal = this.subtotal + abo.subtotal;
      this.discountamount = this.discountamount + abo.discamt;
      this.taxableamt = this.taxableamt + abo.sttaxable;
      this.taxamt = this.taxamt + abo.sttaxamt;
      this.nettotal = this.nettotal + abo.nettotal;
    }
  }

  //Get 
  fngetStatus() {
    var data = {
      'statusid': 0,
    }
    this.appService.getStatus(data)
      .subscribe(
        (res) => {
          this.statusColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      // if(this.orderform.valid){
      //Check
      var data = {
        'orderid': this.orderid,
        'statusid': this.orderform.get('statusid').value,
      }

      if (this.orderform.get('orderid').value > 0) {
        //Update Department
        //  this.fnSetOrderStatus(data);
      }

      // }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Close
  fnCloseOrder() {
    try {

      if (this.customerid > 0) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': this.customerid,
          }
        }

        this.router.navigate(['/CustomerView'], navigationExtras);
      }
      else {

        this.router.navigate(['/Orders']);

      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }





  //Sales Invoice
  fnSetSalesInvoice() {
    var data = {
      'orderid': this.orderid,
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.fnShowProgress();
    this.appService.fnSetSalesInvoice(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().result != "") {
          this.toastr.success('', "The order has been confirmed successfully.");
          this.fnCloseOrder();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }


  fnGetQtyCount() {
    let _aqty: Number = 0;

    for (let _abo of this.shipmentdetailscoll) {
      _aqty = _aqty + _abo.deliveryqty;
    }

    return _aqty;
  }

  // Order Option
  fnOrderOption() {
    const modalRef = this.productFindService.open(OrderoptionComponent, { size: <any>'l' });

    modalRef.componentInstance.orderid = this.ordermainBo.orderid;
    modalRef.componentInstance.orderno = this.ordermainBo.orderno;
    modalRef.componentInstance.doctype = '';
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      if (receivedEntry === 'REJECTORDER') {
        this.fnCancelOrder();
      }
      else if (receivedEntry === 'REMOVEDELIVERYCHARGES') {
        this.fnRemoveDeliveryChargesOrder();
      }
    });

  }

  //Cancel Order
  fnCancelOrder() {
    const modalRef = this.productFindService.open(CancelorderComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "CANCELORDER";
    modalRef.componentInstance.docname = "Order Cancell";
    modalRef.componentInstance.orderid = this.ordermainBo.orderid;
    modalRef.componentInstance.orderno = this.ordermainBo.orderno;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      var _checkresult = receivedEntry[0].checkresult;
      if (_checkresult === 'DONE') {
        this.fnInsertActivityLog('CANCELORDER', this.ordermainBo.orderno, "", 0);
        this.fnSendMailByOrder('CANCELORDER');
      }
      else {
        this.toastr.warning('', "Already confirmed this order.");
        window.location.reload()
      }



    });

  }

  //Remove Delivery Charges Order
  fnRemoveDeliveryChargesOrder() {

    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Remove delivery Charges Order?')
      .then((confirmed) => {
        if (confirmed) {

          var data = {
            'orderid': this.orderid,
            'isdelivchremoved': true,
            'modifiedby': this.loginService.fnGetModUserId(),

          }
          this.appService.fnSetOrderRemovedDeliveryCharges(data)
            .subscribe(
              (res) => {
                this.fnInsertActivityLog('REMOVEDELIVERYCHARGESORDER', this.ordermainBo.orderno, "", 0);

                window.location.reload()
              },
              (err) => {
                //this.toastr.error('Error', err);
              })
        }
      });




  }

  //Change Delivery Date Time Order 
  fnChangeDeliveryDt() {


    const modalRef = this.productFindService.open(EditdeliverydateComponent, { size: <any>'l' });

    modalRef.componentInstance.orderid = this.ordermainBo.orderid;
    modalRef.componentInstance.expectdeliverydate = this.fnConvertDatetime(this.fnFormatDatetime(this.ordermainBo.expectdeliverydate));
    modalRef.componentInstance.expectdeliverytime = this.ordermainBo.expectdeliverytime;


    console.log(this.ordermainBo.expectdeliverydate + " " + this.ordermainBo.expectdeliverytime)

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fnInsertActivityLog('CHANGEDELIVERYDT', this.ordermainBo.orderno, "", 0);

      window.location.reload()

    });

  }

  //Change Customer Avail Date Time Order 
  fnChangeCustomerAvailDt() {


    const modalRef = this.productFindService.open(CustomeravaildateComponent, { size: <any>'l' });

    modalRef.componentInstance.orderid = this.ordermainBo.orderid;
    modalRef.componentInstance.customeravaildate = this.fnConvertDatetime(this.fnFormatDatetime(this.ordermainBo.customeravaildate));
    modalRef.componentInstance.customeravailtime = this.ordermainBo.customeravailtime;


    console.log(this.ordermainBo.customeravaildate + " " + this.ordermainBo.customeravailtime)

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fnInsertActivityLog('CHANGECUSTOMERAVAILDT', this.ordermainBo.orderno, "", 0);

      window.location.reload()

    });

  }


  //Cancel Product
  fnCancelProductOrder(index) {
    const modalRef = this.productFindService.open(CancelorderComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "CANCELPRODUCT";
    modalRef.componentInstance.docname = "Product Cancell";
    modalRef.componentInstance.orderno = this.ordermainBo.orderno;
    modalRef.componentInstance.productBO = this.orderdetailsColl[index];
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      var _checkresult = receivedEntry[0].checkresult;
      if (_checkresult === 'DONE') {
        this.fnInsertActivityLog('CANCELPRODUCT', this.ordermainBo.orderno, this.orderdetailsColl[index].sku + "-" + this.orderdetailsColl[index].productname, 0);
        this.fnSendMailByOrder('CANCELLEDPRODUCT');
      }
      else {
        this.toastr.warning('', "Already confirmed this order.");
        window.location.reload()
      }



    });

  }

  //Show Cancel Reason
  fnShowCancelReason(str) {
    this.confirmationDialogService.confirm('ALERT', str);
  }

  //Add Product
  fnSelectProduct() {

    const modalRef = this.productFindService.open(ProductfindComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "SELECT PRODUCT";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      for (let wbo of receivedEntry) {

        var data = {
          'orderid': this.orderid,
          'productid': wbo.productid,
          'skuid': wbo.skuid,
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnSetOrderItemAdd(data)
          .subscribe(
            (res) => {
              this.fnInsertActivityLog('ADDPRODUCT', this.ordermainBo.orderno, wbo.productid + "-" + wbo.skuid, 0);
              window.location.reload();
            },
            (err) => {
              //this.toastr.error('Error', err);
            })
      }
    });

  }

  //Qty Change Product
  fnQtyChangeProductOrder(index) {
    const modalRef = this.productFindService.open(EditordersComponent, { size: <any>'l' },);
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.orderno = this.ordermainBo.orderno;
    //modalRef.componentInstance.productBO=this.orderdetailsColl[index];
    modalRef.componentInstance.orderdetailsid = this.orderdetailsColl[index].orderdetailsid;
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.qty = this.orderdetailsColl[index].qty;
    modalRef.componentInstance.productname = this.orderdetailsColl[index].productname;
    modalRef.componentInstance.paytype = this.orderpayBo.paytype;
    modalRef.componentInstance.oldqty = this.orderdetailsColl[index].oldqty;


    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      var _checkresult = receivedEntry[0].checkresult;
      if (_checkresult === 'DONE') {
        this.fnInsertActivityLog('QTYCHANGEPRODUCT', this.ordermainBo.orderno, this.orderdetailsColl[index].sku + "-" + this.orderdetailsColl[index].productname, 0);

      }
      else {
        this.toastr.warning('', "Already confirmed this order.");
      }
      window.location.reload()

    });

  }

  fnAddDeliveryOrder() {
    try {

      let navigationExtras: NavigationExtras = {
        queryParams: {
          'oid': this.orderid,
          'ltype': 'order'
        }
      }

      this.router.navigate(['/AddShipment'], navigationExtras);

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditDeliveryOrder(aBO) {
    try {

      let navigationExtras: NavigationExtras = {
        queryParams: {
          'oid': this.orderid,
          'id': aBO.deliveryorderid,
          'ltype': 'order'
        }
      }

      this.router.navigate(['/AddShipment'], navigationExtras);

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Zoom Product
  fnShowZoomProduct(skuid) {
    const modalRef = this.productFindService.open(ZoomproductComponent, { size: <any>'l' },);
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.skuid = skuid;
    modalRef.componentInstance.fnGetProductInfo();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });

  }

  //Send Mail
  fnSendMailByOrder(mailtype) {

    this.fnShowProgress();
    var data = {
      orderid: this.orderid,
      mailtype: mailtype,
    }
    this.appService.fnSendMailByOrder(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.fnSendNotificationByOrder(mailtype)
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnSendNotificationByOrder(type) {

    this.fnShowProgress();
    var data = {
      orderid: this.orderid,
      actiontype: type,
    }
    this.appService.fnSendNotificationByOrder(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            window.location.reload()
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  private fnEditMode(deptBO) {
    this.orderform.setValue({
      orderno: deptBO.orderno,
      orderid: deptBO.orderid,
      orderdate: deptBO.orderdate,
      customername: deptBO.customername,
      mobileno: deptBO.mobileno,
      addressnickname: deptBO.addressnickname,
      houseno: deptBO.houseno,
      street: deptBO.street,
      areano: deptBO.areano,
      cityname: deptBO.cityname,
      landmark: deptBO.landmark,
      statusid: deptBO.statusid,
      status: deptBO.status,
    });

    this.ordermainBo.expectdeliverydate = this.fnConvertDatetime(this.fnFormatDatetime(this.ordermainBo.expectdeliverydate));

    this.orderconfirmform.setValue({
      deliverytypeid: this.ordermainBo.deliverytypeid,
      expectdeliverydate: this.ordermainBo.expectdeliverydate,
      expectdeliverytime: this.ordermainBo.expectdeliverytime,
      branchid: null,
    });


    // console.log(this.ordermainBo.expectdeliverydt)


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = true;
  }


  public fnUnConfirm() {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Unconfirm Order?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnUnConfitmationOrder()
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnUnConfitmationOrder() {
    var data = {
      'orderid': this.orderid,
    }
    this.appService.fnSetOrderUnConfirmation(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Order has been unconfirm successfully.");
            window.location.reload();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  public fnDeleteConfirm(deptid) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Delivery Order?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteDeliveryOrder(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteDeliveryOrder(deptid) {
    var data = {
      'deliveryorderid': deptid,
    }
    this.appService.fnDeleteDeliveryOrder(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Delivery Order has been deleted successfully.");
            window.location.reload();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnGeoLocationDeliveryAddress(latitude, longitude) {


    const modalRef = this.productFindService.open(OrderaddressmapComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "COMBO PRODUCT";
    modalRef.componentInstance.latitude = latitude;
    modalRef.componentInstance.longitude = longitude;
    modalRef.componentInstance.arealatitude = this.orderaddressBo.arealatitude;
    modalRef.componentInstance.arealongitude = this.orderaddressBo.arealongitude;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });


  }

  fnEditOrderAddress(aBO) {
    try {

      let navigationExtras: NavigationExtras = {
        queryParams: {
          'oid': this.orderid,
          'id': aBO.orderaddressid,
        }
      }

      this.router.navigate(['/AddOrderAddress'], navigationExtras);

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }




  downloadPdf() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const content = this.reportContent.nativeElement;

    doc.fromHTML(content.innerHTML, 15, 15, {
      'width': 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save('asdfghj' + '.pdf');
  }

  //PRINT

  fnPrintOrderItem() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT PICKLIST";
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.fnPrintPickList();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      // window.location.reload()      
    });



    // var data = {
    //   'orderid':this.orderid,            
    // }
    // this.appService.fnGetInvoiceOrder(data)
    // .subscribe(
    //   (res)=>{                  


    // },
    // (err)=>{          
    //   this.toastr.error('Error', err);
    // })
  }

  fnPrintOrder() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT ORDER";
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.fnPrintOrder();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      // window.location.reload()      
    });



    // var data = {
    //   'orderid':this.orderid,            
    // }
    // this.appService.fnGetInvoiceOrder(data)
    // .subscribe(
    //   (res)=>{                  


    // },
    // (err)=>{          
    //   this.toastr.error('Error', err);
    // })
  }

  fnPrintInvoice() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT INVOICE";
    modalRef.componentInstance.orderid = this.orderid;
    modalRef.componentInstance.fnPrintOrderInvoice();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    });



  }

  fnPrintDOSlip() {

    const modalRef = this.productFindService.open(PrintorderComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRINT DOSLIP";
    modalRef.componentInstance.deliveryorderid = this.deliveryorderBO.deliveryorderid;
    modalRef.componentInstance.fnPrintDeliveryOrderSlip();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    });



  }


  fnViewDeliveryOrder(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'oid': this.orderid,
          'id': deptid,
          'ltype': 'list'
        }
      }

      this.router.navigate(['/AddShipment'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }


  //Confirm Status
  fnConfirmStatusBtnClick() {
    try {
      const modalRef = this.productFindService.open(ConfirmorderComponent, { size: <any>'l' });
      modalRef.componentInstance.docname = "Confirm Order"
      modalRef.componentInstance.orderid = this.ordermainBo.orderid;
      modalRef.componentInstance.deliverytypeid = this.orderconfirmform.get('deliverytypeid').value,
        modalRef.componentInstance.expectdeliverydate = this.fnFormatDatetime(this.orderconfirmform.get('expectdeliverydate').value),
        modalRef.componentInstance.expectdeliverytime = this.orderconfirmform.get('expectdeliverytime').value,
        modalRef.componentInstance.ordermainBo = this.ordermainBo;

      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        if (receivedEntry.status === 'DONE') {
          // this.fnSaveShipment(receivedEntry);            

          this.toastr.success('', "The order has been confirmed successfully.");
          this.fnCloseOrder();

        }
      });


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnConfirmStatus() {
    var data = {
      'orderid': this.orderid,
      'status': 'CONFIRMED',
      'deliverytypeid': this.orderconfirmform.get('deliverytypeid').value,
      'expectdeliverydate': this.fnFormatDatetime(this.orderconfirmform.get('expectdeliverydate').value),
      'expectdeliverytime': this.orderconfirmform.get('expectdeliverytime').value,
      'modifiedby': this.loginService.fnGetModUserId()
    }

    if (this.orderform.get('orderid').value > 0) {
      //Update Department
      this.appService.fnSetOrderConfirm(data).subscribe(
        (res) => {

          if (res.json().result != "") {
            var _result = res.json().result[0];
            var _checkresult = "";

            if (_result.length) {
              _checkresult = _result[0].checkresult;

              if (_checkresult === 'DONE') {
                this.fnInsertActivityLog('CONFIRMED', this.ordermainBo.orderno, this.ordermainBo.mobile, 0);

                // this.fnSaveShipment(null);
              }
              else {
                this.toastr.warning('', "Already confirmed this order.");
                this.fnCloseOrder();
              }

            }


          }
        },
        (err) => {
          this.toastr.error('Error', err);
        });

    }

  }



  //Shipment
  //Delivery Order Status

  fnDeliveryOrderReadyToPick() {
    const modalRef = this.productFindService.open(ReadytopickorderComponent, { size: <any>'l' });
    modalRef.componentInstance.docname = "Create Shipment"
    modalRef.componentInstance.orderid = this.ordermainBo.orderid;
    modalRef.componentInstance.ordermainBo = this.ordermainBo;

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      console.log(receivedEntry)
      if (receivedEntry.status === 'DONE') {
        this.fnShowProgress();

        //this.fnSetReadyToPickDeliveryOrder(dBO.deliveryorderid,receivedEntry)
        this.fnSaveShipment(receivedEntry)

        this.fnHideProgress();
      }
    });
  }

  //Save Shipment
  fnSaveShipment(receivedEntry) {
    try {
      this.shipmentdetailscoll = [];

      for (let _abo of this.orderdetailsColl) {
        if (!_abo.iscancelled) {
          _abo.deliveryqty = _abo.qty;

          this.shipmentdetailscoll.push(_abo)
        }
      }

      //Check
      var data = {
        'deliveryorderid': 0,
        'deliveryorderno': '',
        'deliveryorderdate': this.fnFormatDatetime(new Date()),
        'productcount': this.shipmentdetailscoll.length,
        'qtytotal': this.fnGetQtyCount(),
        'shipmenttypeid': receivedEntry.shipmenttypeid,
        'trackno': receivedEntry.trackno,
        'branchid': receivedEntry.branchid,
        'orderid': this.orderid,
        'createdby': this.loginService.fnGetModUserId(),
        'modifiedby': this.loginService.fnGetModUserId(),
        'deliveryorderdetailscoll': this.shipmentdetailscoll
      }

      //Insert Shipment
      this.fnInsertShipment(data);


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  //Insert
  fnInsertShipment(data) {
    this.fnShowProgress();
    this.appService.fnInsertDeliveryOrder(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().message === "DONE") {
          this.fnInsertActivityLogShipment('INSERT', res.json().result, this.ordermainBo.orderno, res.json().result);
          this.toastr.success('', "The shipment has been created successfully.");
          this.fnCloseOrder();
        }
        else if (res.json().message === "FAILED") {
          this.toastr.warning('', "Already shipped this Order");
          this.fnCloseOrder();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }

  fnSetReadyToPickDeliveryOrder(deliveryorderid, receivedEntry) {

    var data = {
      deliveryorderid: deliveryorderid,
      isreadytopick: true,
      trackno: receivedEntry.trackno,
      shipmenttypeid: receivedEntry.shipmenttypeid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReadyToPick(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _result = res.json().result[0][0].checkresult
            if (_result === 'DONE') {
              this.fnInsertActivityLog('READYTOPICK', deliveryorderid, this.ordermainBo.orderno, this.orderid);
              this.fnCloseOrder();

            }
            else if (_result === 'EXISTREADYTOPICK') {
              this.toastr.warning('', "Already Readytopick this Shipment");
              this.fnCloseOrder();
            }
            else if (_result === 'EXISTASSIGNED') {
              this.toastr.warning('', "Already Assigned this Shipment");
              this.fnCloseOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnReadyToPickDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreadytopick: false,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReadyToPick(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _result = res.json().result[0][0].checkresult
            if (_result === 'DONE') {
              this.fnInsertActivityLog('UNREADYTOPICK', deliveryorderid, this.ordermainBo.orderno, this.orderid);

              this.fnCloseOrder();
            }
            else if (_result === 'EXISTASSIGNED') {
              this.toastr.warning('', "Already Assigned this Shipment");
              this.fnCloseOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  //Delivery Order Option
  fnDeliveryOrderOption() {
    const modalRef = this.productFindService.open(OrderoptionComponent, { size: <any>'l' });

    modalRef.componentInstance.doctype = 'SHIPMENT';
    modalRef.componentInstance.deliveryorderBO = this.deliveryorderBO;
    modalRef.componentInstance.isdeleteshipment = this.isdeleteshipment;
    modalRef.componentInstance.isorderreadytopick = this.isorderreadytopick;
    modalRef.componentInstance.isorderassign = this.isorderassign;
    modalRef.componentInstance.isorderpick = this.isorderpick;
    modalRef.componentInstance.isorderdeliver = this.isorderdeliver;
    modalRef.componentInstance.isorderreceived = this.isorderreceived;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


      if (receivedEntry === 'REVERTCONFIRMATION') {
        this.fnUnConfirm();
      }
      else if (receivedEntry === 'REVERTSHIPMENT') {
        this.fnDeleteConfirm(this.deliveryorderBO.deliveryorderid);
      }
      else if (receivedEntry === 'UNREADYTOPICK') {
        this.fnConfirmRevertStatus('UnReadyToPick');
      }
      else if (receivedEntry === 'ASSIGN') {
        this.fnDeliveryOrderAssigned();
      }
      else if (receivedEntry === 'UNASSIGN') {
        this.fnConfirmRevertStatus('UnAssign');
      }
      else if (receivedEntry === 'PICK') {
        this.fnDeliveryOrderPicked();
      }
      else if (receivedEntry === 'UNPICK') {
        this.fnConfirmRevertStatus('UnPick');
      }
      else if (receivedEntry === 'DELIVER') {
        this.fnDeliveryOrderDelivered();
      }
      else if (receivedEntry === 'UNDELIVER') {
        this.fnConfirmRevertStatus('UnDelivery');
      }
      else if (receivedEntry === 'RECEIVED') {
        this.fnSetReceivedDeliveryOrder(this.deliveryorderBO.deliveryorderid);
      }
      else if (receivedEntry === 'UNRECEIVED') {
        this.fnSetUnReceivedDeliveryOrder(this.deliveryorderBO.deliveryorderid);
      }

    });

  }


  //RevertStatus
  fnConfirmRevertStatus(type) {

    let msg = "";


    if (type === 'UnAssign') {
      msg = "Revert Assign Driver";
    }
    else if (type === 'UnReadyToPick') {
      msg = "Revert ReadyToPick";
    }
    else if (type === 'UnPick') {
      msg = "Revert Pick";
    }
    else if (type === 'UnDelivery') {
      msg = "Revert Delivery";
    }
    else if (type === 'UnReceive') {
      msg = "Revert Settlement";
    }


    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to ' + msg + ' Shipment?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();



          if (type === 'UnAssign') {
            this.fnSetUnAssignDeliveryOrder(this.deliveryorderBO.deliveryorderid)
          }
          else if (type === 'UnReadyToPick') {
            this.fnSetUnReadyToPickDeliveryOrder(this.deliveryorderBO.deliveryorderid)
          }
          else if (type === 'UnPick') {
            this.fnSetUnPickDeliveryOrder(this.deliveryorderBO.deliveryorderid)
          }
          else if (type === 'UnDelivery') {
            this.fnSetUnDeliveredDeliveryOrder(this.deliveryorderBO.deliveryorderid)
          }
          else if (type === 'UnReceive') {
            this.fnSetUnReceivedDeliveryOrder(this.deliveryorderBO.deliveryorderid)
          }


          this.fnHideProgress();
        }
      }
      );

  }


  //Assign
  fnDeliveryOrderAssigned() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.displaytxt = "Assign Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();

      this.fnSetAssignDeliveryOrder(this.deliveryorderBO.deliveryorderid, deliveryboyid)


      this.fnHideProgress();

      this.fnCloseOrder();

    });

  }

  fnSetAssignDeliveryOrder(deliveryorderid, deliveryboyid) {

    var data = {
      deliveryorderid: deliveryorderid,
      isassigned: true,
      assignedby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderAssigned(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {

            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.appService.fnSendPushNotification(_res.title, _res.content, _res.orderid, _res.deliveryboyid, deliveryorderid);

              this.fnInsertActivityLog('ASSIGNED', deliveryboyid, this.deliveryorderBO.deliveryorderid, this.orderid);

              this.fnCloseOrder();
            }
            else if (_res.checkresult === 'EXISTASSIGNED') {
              this.toastr.warning('', "Already Assigned this Shipment");
              this.fnCloseOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnAssignDeliveryOrder(deliveryorderid) {

    var data = {
      deliveryorderid: deliveryorderid,
      isassigned: false,
      assignedby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderAssigned(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLog('UNASSIGNED', deliveryorderid, this.deliveryorderBO.deliveryorderid, this.orderid);
              this.fnCloseOrder();
            }
            else if (_res.checkresult === 'EXISTPICKED') {
              this.toastr.warning('', "Already Pick this Shipment");
              this.fnCloseOrder();
            }

          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //Picked
  fnDeliveryOrderPicked() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "Order Pick";
    modalRef.componentInstance.deliveryboyid = this.deliveryorderBO.assignedby
    modalRef.componentInstance.displaytxt = "Picked Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();


      this.fnSetPickDeliveryOrder(this.deliveryorderBO.deliveryorderid, deliveryboyid)


      this.fnHideProgress();

      //this.fngetOrderListView();

    });

  }

  fnSetPickDeliveryOrder(deliveryorderid, deliveryboyid) {


    var data = {
      deliveryorderid: deliveryorderid,
      ispicked: true,
      pickedby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderPicked(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];

            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLog('PICKED', deliveryboyid, this.deliveryorderBO.deliveryorderid, this.orderid);
              this.fnSendNotificationByOrder('PICKED');
            }
            else if (_res.checkresult === 'EXISTPICKED') {
              this.toastr.warning('', "Already Picked this Shipment");
              this.fnCloseOrder();
            }

          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnPickDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      ispicked: false,
      pickedby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderPicked(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];

            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLog('UNPICKED', deliveryorderid, this.deliveryorderBO.deliveryorderid, this.orderid);
              this.fnCloseOrder();
            }
            else if (_res.checkresult === 'EXISTDELIVERED') {
              this.toastr.warning('', "Already Deliver this Shipment");
              this.fnCloseOrder();
            }

          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnDeliveryOrderPickedAnotherShipment() {
    this.fnShowProgress();


    this.fnSetPickDeliveryOrder(this.deliveryorderBO.deliveryorderid, null)


    this.fnHideProgress();

  }


  //Delivered
  fnDeliveryOrderDelivered() {
    const modalRef = this.productFindService.open(AdddeliveryoptionComponent, { size: <any>'l' });
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.deliveryboyid = this.deliveryorderBO.assignedby
    modalRef.componentInstance.displaytxt = "Delivered Delivery Boy";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      let deliveryboyid = 0

      deliveryboyid = receivedEntry.deliveryboyid;

      this.fnShowProgress();

      this.fnSetDeliveredDeliveryOrder(this.deliveryorderBO.deliveryorderid, deliveryboyid)

      this.fnHideProgress();



    });

  }

  fnSetDeliveredDeliveryOrder(deliveryorderid, deliveryboyid) {

    this.fnShowProgress();
    var data = {
      deliveryorderid: deliveryorderid,
      isdelivered: true,
      deliveredby: deliveryboyid,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderDelivered(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLog('DELIVERED', deliveryboyid, this.deliveryorderBO.deliveryorderid, this.orderid);
              this.fnCloseOrder();
              //this.fnSendMailByOrder()
            }
            else if (_res.checkresult === 'EXISTDELIVERED') {
              this.toastr.warning('', "Already Deliver this Shipment");
              this.fnCloseOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnDeliveredDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isdelivered: false,
      deliveredby: 0,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderDelivered(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            var _res = res.json().result[0][0];
            if (_res.checkresult === 'DONE') {
              this.fnInsertActivityLog('UNDELIVERED', deliveryorderid, this.deliveryorderBO.deliveryorderid, this.orderid);
              this.fnCloseOrder();
            }
            else if (_res.checkresult === 'EXISTRECEIVED') {
              this.toastr.warning('', "Already Receive Amount this Shipment");
              this.fnCloseOrder();
            }
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnDeliveryOrderDeliveredAnotherShipment() {
    this.fnShowProgress();

      this.fnSetDeliveredDeliveryOrder(this.deliveryorderBO.deliveryorderid, null)

      this.fnHideProgress();


  }

  //Received
  fnDeliveryOrderReceived() {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Received Payment?')
      .then((confirmed) => {
        if (confirmed) {
          this.fnShowProgress();

          this.fnSetReceivedDeliveryOrder(this.deliveryorderBO.deliveryorderid)

          this.fnHideProgress();
        }
      }
      );

  }

  fnSetReceivedDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreceived: true,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReceived(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnCloseOrder();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnSetUnReceivedDeliveryOrder(deliveryorderid) {


    var data = {
      deliveryorderid: deliveryorderid,
      isreceived: false,
      modifiedby: this.loginService.fnGetModUserId(),
    }
    this.appService.fnSetDeliveryOrderReceived(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.fnCloseOrder();
          }
          else if (res.json().status == 400) {
            this.fnHideProgress();
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnShowMoreInfo() {
    this.isshowmoreinfo = !this.isshowmoreinfo;
  }




  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  fnInsertActivityLog(action, refno, desc, doid) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: this.orderid,
        deliveryorderid: doid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnInsertActivityLogShipment(action, refno, desc, doid) {
    try {
      var data = {
        logtype: action,
        logat: "SHIPMENT",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
        orderid: this.orderid,
        deliveryorderid: doid
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
