import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,AfterViewChecked {
 
 //Save Validation
 submitted:boolean;
 successfully:boolean;

 userColl:any=[];

  loginform=new FormGroup({
    username:new FormControl('',Validators.required),         
    userpwd:new FormControl('',Validators.required),
});


  constructor(private loginService: LoginDataService,private appService: DhukanDataService,
    private toastr: ToastrService,public router: Router) { }

  ngOnInit() {
    this.fnInitializeFoodSymbolFormGroup();
    
    //setTimeout(() => this.appService.islogin = false);
       this.loginService.islogin=false;
  }

    ngAfterViewChecked(){
      this.loginService.islogin = true;
    }

  fnInitializeFoodSymbolFormGroup(){
    this.loginform.setValue({
      username:'',      
      userpwd:''
    })
}

  fnLogin(){
    try{
      this.submitted=true;
      this.successfully=false;
      //Validate
      if(this.loginform.valid){
        var data = {
          'username':this.loginform.get('username').value, 
          'userpwd':this.loginform.get('userpwd').value, 
        }
        this.appService.getUserlogin(data)
        .subscribe(
          (res)=>{   
                       
            if(res.json().status===200){
              this.userColl=res.json().result;  
             
              if(this.userColl){

                if(this.userColl.length>0){
                   

                    localStorage.setItem('adtoken', JSON.stringify(this.userColl[0].userid));   
                    this.loginService.fnGetUserRoleList();
                    this.loginService.islogin=false;

                    if(this.userColl[0].isscreencheck===1){
                      var _screen="";
                      _screen=this.userColl[0].landingscreen;

                      this.fnInsertActivityLog('LOGIN',this.userColl[0].loginuserid,this.userColl[0].username);
                     
                      if(_screen==='ORDER'){
                        this.router.navigate(['/Orders']).then(()=>{
                          window.location.reload();
                        });                        
                      }
                      else if(_screen==='SHIPMENT'){
                        this.router.navigate(['/DeliveryOrders']).then(()=>{
                          window.location.reload();
                        });                        
                      }
                      else if(_screen==='SETTLEMENT'){
                        this.router.navigate(['/Settlement']).then(()=>{
                          window.location.reload();
                        });                        
                      }
                      else if(_screen==='PRODUCT'){
                        this.router.navigate(['/Product']).then(()=>{
                          window.location.reload();
                        });                        
                      }                     
                      else{
                        this.router.navigate(['/']).then(()=>{
                          window.location.reload();
                        });
                      }

                    }
                }else{
                  this.loginform.controls['username'].setErrors({'notfound': true});
                }

              }

            }   
                     
        },
        (err)=>{          
          this.toastr.error('Error', err);
        })
       }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }


  
 fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:"LOGIN",
      refnum:refno,
      logdescr:desc,
      logby:refno,
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


  
  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }

}
